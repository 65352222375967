:root {
  --background-color-light: #ffffff;
  
  --text-color-light: #000000;

  --background-color-dark: #1a1a1a;

  --text-color-dark: #ffffff;

  --header-bg-light: #ffffff;
  --header-bg-dark: #212121;

  --message-bg-light: #f0f0f0;
  --message-bg-dark: #212121;

  --message-text-light: #000000;
  --message-text-dark: #ffffff;

  --font-family: 'Tajawal', sans-serif;
}

/* Light mode */
.background-light {
  --background-color: var(--background-color-light);
  --text-color: var(--text-color-light);
  --header-bg: var(--header-bg-light);
  --message-bg: var(--message-bg-light);
  --message-text: var(--message-text-light);
}

/* Dark mode */
.background-dark {
  --background-color: var(--background-color-dark);
  --text-color: var(--text-color-dark);
  --header-bg: var(--header-bg-dark);
  --message-bg: var(--message-bg-dark);
  --message-text: var(--message-text-dark);
}

body, .bpContainer, .bpMessageListContainer, .bpComposerContainer, .bpHeaderContainer, .bpMessageContainer, .bpMessageBlocksBubble, .bpComposerInput, .bpHeaderContentTitle, .bpMessageListMarqueeTitle {
  font-family: var(--font-family);
}

.bpMessageListContainer {
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  flex-grow: 1;
  padding: var(--bpSpacing-1);
  position: relative;
  background-color: var(--background-color);
  color: var(--text-color);
}

.bpComposerContainer {
  background-color: var(--background-color);
  color: var(--text-color);
}

.bpMessageBlocksTextText {
  direction: rtl;
}

.bpContainer {
  background-color: var(--background-color);
  /* color: var(--text-color); */
  border-color: var(--background-color);
  border-width: 0 !important;
}

.bpHeaderContainer:not([data-disabled]) .bpHeaderContentContainer:hover {
  background-color: var(--background-color);
}

.bpHeaderContainer {
  background-color: var(--header-bg);
  box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
  color: var(--text-color);
}

.bpMessageContainer[data-direction=incoming] .bpMessageBlocksBubble {
  border-color: var(--bpGray-200);
  background-color: var(--message-bg);
  color: var(--message-text);
  border-bottom-left-radius: var(--bpRadius-sm);
}

.bpMessageContainer[data-direction=outgoing] .bpMessageBlocksBubble {
  background-color: var(--message-bg);
  color: var(--message-text);
  border-bottom-right-radius: var(--bpRadius-sm);
}


.chatbot__container {
  width: 100vw;
  height: 80vh;
  margin: 80px auto 0 auto;
  border: none !important;
}

.bp-widget {
  background-color: inherit;
  color: inherit;
}

.bp-message {
  background-color: inherit;
  color: inherit;
}

.bp-input {
  background-color: inherit;
  color: inherit;
}

.bpMessageBlocksTextText{
  direction: rtl !important;
}

.bpComposerInput {
  background-color: var(--background-color);
  color: var(--text-color);
}

.bpHeaderContentTitle,
.bpMessageListMarqueeTitle {
  color: var(--text-color);
}


.bpComposerVoiceButtonContainer {
  display: none;
}

/* .bpComposerInput {
  background-color: var(--background-color);
  color: var(--text-color);
  &::placeholder {
    content: "اكتب هنا";
    color: var(--text-color);
    opacity: 0.7;
    direction: rtl;
    text-align: right;
  }
} */

.bpComposerInput::placeholder {
  content: "اكتب هنا" !important;
  color: var(--text-color) !important;
  opacity: 0.7 !important;
  direction: rtl !important;
  text-align: right !important;
}


/* back btn */
.back__arrow{
  font-size: 20px;
}


.webview-mode {
  margin: 0 !important;
  padding: 0 !important;
}

.ourBpMessageListScrollDownButtonButton{
      display: flex;
      gap: var(--bpSpacing-1);
      padding-left: var(--bpSpacing-3);
      padding-right: var(--bpSpacing-3);
      padding-top: var(--bpSpacing-1);
      padding-bottom: var(--bpSpacing-1);
      background-color: var(--bpGray-800);
      color: var(--bpGray-1);
      font-size: .75rem;
      line-height: 1rem;
      align-items: center;
      border-radius: calc(var(--bpRadius-scale) * var(--bpRadius-full));
      cursor: pointer;
      transition-property: all;
      transition-timing-function: cubic-bezier(.4,0,.2,1);
      transition-duration: .15s;
      content: "";
  }


.bpMessageListScrollDownButtonButton {
  display: none; 
}

@media (max-width: 768px) {
  .chatbot__container {
    margin: 65px auto 0 auto;
    height: 80vh;
  }

  .bpMessageListMarqueeContainer {
    display: none;
  }

  .bpMessageContainer[data-direction=system] {
    margin-top: 0 !important;
  }
}